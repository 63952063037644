export enum CrewCalendarStatusType {
  WorkDay = 1,
  RestDay,
  SickDay,
  Vacation,
  UnpaidVacation,
  Unknown,
}

export class CrewCalendarStatus {
  constructor(
    public readonly id: CrewCalendarStatusType,
    public readonly title: string,
    public readonly additionalClass: string,
  ) {
  }
}
