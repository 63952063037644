import {BudgetPeriod} from '../budget';
import {
  activeCurrency,
  ContractTypeDto,
  CurrencyDto,
  PositionsGroupDto,
  RoleDto,
  RolePositionDto,
  RolePositionTypeDto,
} from '../directories';
import {LabeledItem} from '../general';

export enum ContractPeriodList {
  DAY,
  MONTH
}

export class ContractPeriod {
  constructor(
    public readonly id: ContractPeriodList | BudgetPeriod,
    public readonly name: string,
  ) {
  }
}

export class CrewContractDirectories {

  readonly currency = activeCurrency(this.currencyList);
  readonly positions: RolePositionDto[] = this.rolePositionType.reduce((acc, value) => [...acc, ...value.positions], []);

  constructor(
    private readonly currencyList: CurrencyDto[],
    public readonly contractType: ContractTypeDto[],
    public readonly roles: RoleDto[],
    public readonly rolePositionType: RolePositionTypeDto[],
    public readonly periods: ContractPeriod[],
    public readonly userGroups: LabeledItem[],
    public readonly positionsGroup?: PositionsGroupDto[],
  ) {
  }
}
