import {CrewCalendarDto, CrewPositionDto, SalaryDto} from './crew.model';

import {CrewCvAvailabilityDto, CrewCvCompletedPercentDto} from '../crew-cv';
import {CrewStatuses} from '../expense';
import {PaginationDto} from '../response';

export class CrewPreviewDto {
  constructor(
    public readonly userId: number,
    public readonly name: string,
    public readonly image: string,
  ) {
  }
}

export class CrewNamesDto {
  constructor(
    public readonly id: number,
    public readonly avatar: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly age: number,
    public readonly lastLogin: string,
    public readonly email?: string,
  ) {
  }
}

export class CrewListItem {
  constructor(
    public readonly boatContractId: number,
    public readonly user: CrewNamesDto,
    public readonly cvCompleted: CrewCvCompletedPercentDto,
    public readonly status: CrewStatuses,
    public readonly salary: SalaryDto,
    public readonly contract: string,
    public readonly position: CrewPositionDto,
    public readonly calendar?: CrewCalendarDto,
    public readonly adminBoat?: boolean,
    public readonly lastDateUpdate?: string,
    public comment?: string,
    public note?: string,
    public readonly dateStart?: string,
    public readonly dateEnd?: string,
    public readonly dateInvitation?: string,
    public readonly id?: number,
    public readonly cvAvailable?: CrewCvAvailabilityDto,
    public readonly rating?: number,
    public readonly contractId?: number,
    public readonly showDocumentAlert?: boolean,
    public readonly canOpenDocuments?: boolean,
  ) {
  }
}

export class CrewListDto {
  constructor(
    public readonly myCrewList: CrewListItem[],
    public readonly pagination: PaginationDto,
    public readonly extraData: CrewListBoatDataDto,
    public readonly downloadList: CrewDownloadListItemDto[],

  ) {
  }
}

export class CrewListBoatDataDto {
  constructor(
    public readonly maxCrew: number,
    public readonly crewOnboard: number,
  ) { }
}

export class CrewDownloadListItemDto {
  constructor(
    public readonly id: number,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly avatar: string,
    public readonly position: string,
  ) { }
}

export class CrewStatus {
  constructor(
    public readonly id: CrewStatuses,
    public readonly title: string,
    public readonly style: string,
    public readonly icon: string,
  ) {
  }
}
